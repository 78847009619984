<template>
  <swiper
    class="slider-swiper"
    :class="{
      '--no-padding-bottom': props.length <= 1,
    }"
    :navigation="{
      nextEl: nextButton,
      prevEl: prevButton,
    }"
    :modules="[Pagination, A11y, Navigation]"
    :slides-per-view="props.options?.slidesPerView || 'auto'"
    :pagination="{ clickable: false }"
    :lazy="true"
    @swiper="onSwiper"
  >
    <swiper-slide v-for="index in props.length" :key="index">
      <div class="slider-swiper__slide__wrap">
        <slot :name="`slide-${index - 1}`" />
      </div>
    </swiper-slide>

    <!-- Navigation-->
    <div v-if="props.length > 1" class="swiper-buttons">
      <button
        data-no-cursor
        class="swiper-button-prev"
        ref="prevButton"
        aria-label="Button Prev"
      >
        <img :src="IconArrowPrev" width="47.5" height="32" alt="Prev" />
      </button>
      <button
        data-no-cursor
        class="swiper-button-next"
        ref="nextButton"
        aria-label="Button Next"
      >
        <img :src="IconArrowNext" width="47.5" height="32" alt="Next" />
      </button>
    </div>
  </swiper>
</template>

<script>
export default {
  name: 'WidgetSliderSwiper',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

import { Pagination, A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import IconArrowNext from '@/assets/img/ico/arrow-right-carousel-black.svg'
import IconArrowPrev from '@/assets/img/ico/arrow-left-carousel-black.svg'

const props = defineProps({
  length: {
    type: Number,
    default: 0,
  },
  options: {
    type: Object,
  },
})

const slider = ref(null)
const nextButton = ref(null)
const prevButton = ref(null)

const touch = ref(false)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})

const onSwiper = (swiper) => {
  slider.value = swiper
}
</script>

<style lang="scss">
@import './style.scss';
</style>
