<template>
  <div ref="modalContainer" class="modalContainer curved-border">
    <div :style="{ top: `${props.modal.index * -100}%` }" class="modalSlider">
      <div v-for="(item, index) in list" :key="`modal_${index}`" class="modal">
        <WidgetPicture
          v-if="item.coverImage"
          class="is-cover"
          :image="{ custom: item.coverImage?.responsiveImage }"
          :alt="item.coverImage.alt ? item.coverImage.alt : item.title"
        />
      </div>
    </div>
  </div>

  <div ref="cursor" class="cursor"></div>
  <div ref="cursorLabel" class="cursorLabel">View</div>
</template>

<script setup>
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

// Register CustomEase with GSAP
gsap.registerPlugin(CustomEase)

// Create a custom ease that mimics cubic-bezier(0.32, 0, 0.67, 0)
CustomEase.create('customBezier', 'M0,0 C0.32,0 0.67,0 1,1')

// Assuming `projects` is passed as a prop
const props = defineProps({
  list: Array,
  modal: Object,
})

// Refs to target the elements
const modalContainer = ref(null)
const cursor = ref(null)
const cursorLabel = ref(null)

// GSAP animations
const animateOpen = () => {
  gsap.to(modalContainer.value, {
    scale: 1,
    xPercent: -50,
    yPercent: -50,
    duration: 0.4,
    ease: 'customBezier',
  })

  gsap.to(cursor.value, {
    scale: 1,
    xPercent: -50,
    yPercent: -50,
    duration: 0.4,
    ease: 'customBezier',
  })

  gsap.to(cursorLabel.value, {
    scale: 1,
    xPercent: -50,
    yPercent: -50,
    duration: 0.4,
    ease: 'customBezier',
  })
}

const animateClose = () => {
  gsap.to(modalContainer.value, {
    scale: 0,
    xPercent: -50,
    yPercent: -50,
    duration: 0.4,
    ease: 'customBezier',
  })

  gsap.to(cursor.value, {
    scale: 0,
    xPercent: -50,
    yPercent: -50,
    duration: 0.4,
    ease: 'customBezier',
  })

  gsap.to(cursorLabel.value, {
    scale: 0,
    xPercent: -50,
    yPercent: -50,
    duration: 0.4,
    ease: 'customBezier',
  })
}

// Watch modal state and trigger GSAP animations
watch(
  () => props.modal.active,
  (newValue) => {
    if (newValue) {
      animateOpen()
    } else {
      animateClose()
    }
  },
)

const handleMouseMove = (e) => {
  const { pageX, pageY, clientX, clientY } = e

  // Move container
  let xMoveContainer = gsap.quickTo(modalContainer.value, 'left', {
    duration: 0.8,
    ease: 'power3',
  })
  let yMoveContainer = gsap.quickTo(modalContainer.value, 'top', {
    duration: 0.8,
    ease: 'power3',
  })

  // Move cursor
  let xMoveCursor = gsap.quickTo(cursor.value, 'left', {
    duration: 0.5,
    ease: 'power3',
  })
  let yMoveCursor = gsap.quickTo(cursor.value, 'top', {
    duration: 0.5,
    ease: 'power3',
  })

  // Move cursor label
  let xMoveCursorLabel = gsap.quickTo(cursorLabel.value, 'left', {
    duration: 0.45,
    ease: 'power3',
  })
  let yMoveCursorLabel = gsap.quickTo(cursorLabel.value, 'top', {
    duration: 0.45,
    ease: 'power3',
  })

  // Move elements based on mouse position
  xMoveContainer(pageX)
  yMoveContainer(clientY)
  xMoveCursor(pageX)
  yMoveCursor(clientY)
  xMoveCursorLabel(pageX)
  yMoveCursorLabel(clientY)
}

// On mounted, set up initial animation state
onMounted(() => {
  gsap.set([modalContainer.value, cursor.value, cursorLabel.value], {
    scale: 0,
  })

  // Add mousemove event listener
  window.addEventListener('mousemove', handleMouseMove)
})

// Clean up the event listener when the component is unmounted
onUnmounted(() => {
  window.removeEventListener('mousemove', handleMouseMove)
})
</script>

<style scoped>
@import './style.scss';
</style>
