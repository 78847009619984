<template>
  <div
    class="row-40-25"
    :style="{
      '--background-color-banner': banner.backgroundColor?.hex,
      '--text-color-banner': banner.textColor?.hex,
    }"
  >
    <div
      class="contact-banner curved-border"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      ref="parentbanner"
    >
      <div class="contact-banner__wrap">
        <div
          v-if="hasPreview"
          class="contact-banner-inside__video"
          data-animate="contact-banner-video"
        >
          <WidgetVideo
            class="is-cover"
            :videoUrl="banner.videoUrl"
            :loop="true"
            :forcePlay="isHover"
            :playInViewport="touch"
            :autoplay="false"
            :muted="true"
            :preload="'auto'"
          />
        </div>

        <WidgetPicture
          v-else-if="banner.image"
          class="is-cover"
          :class="{ 'hide-for-small': banner.imageMobile }"
          :image="{ custom: banner.image?.responsiveImage }"
          :lazyLoad="true"
          :alt="banner.image?.responsiveImage?.alt"
        />
        <!-- Immagine Mobile dedicata (opzionale) -->
        <WidgetPicture
          v-if="banner.imageMobile && !hasPreview"
          class="is-cover hide-for-medium"
          :image="{ custom: banner.imageMobile?.responsiveImage }"
          :lazyLoad="true"
          :alt="banner.imageMobile?.responsiveImage?.alt"
        />

        <div class="contact-banner__text">
          <div
            v-if="banner.title"
            v-html="banner.title"
            class="contact-banner__title text-corpo-xl"
          />

          <div
            v-if="banner.description"
            v-html="banner.description"
            class="contact-banner__description text-sans-l-2"
          />

          <div
            v-if="banner.linkLabel && banner.linkUrl"
            class="contact-banner__link"
          >
            <WidgetArrowLink
              :label="banner.linkLabel"
              :link="banner.linkUrl"
              :is-secondary="true"
            />
          </div>
          <div
            v-if="banner.linkSecondaryLabel && banner.linkSecondaryUrl"
            class="contact-banner__link"
          >
            <WidgetArrowLink
              :label="banner.linkSecondaryLabel"
              :link="banner.linkSecondaryUrl"
              :is-secondary="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonInfoBanner',
}
</script>

<script setup>
import { gsap } from 'gsap'

const parentbanner = ref(null)
const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const ease = 'power2.inOut'

const props = defineProps({
  banner: Object,
})

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})

const hasPreview = computed(() => props.banner?.videoUrl)

const onMouseEnter = () => {
  if (touch.value) return false

  // const parent = parentbanner.value
  const video = document.querySelector('[data-animate="contact-banner-video"]')

  isHover.value = true

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onMouseLeave = () => {
  if (touch.value) return false

  /// const parent = parentbanner.value
  const video = document.querySelector('[data-animate="contact-banner-video"]')

  isHover.value = false

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
