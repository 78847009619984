<template>
  <div
    class="click-hold-cursor-mask"
    :class="{
      'is-light': !props.isDark,
      'is-dark': props.isDark,
    }"
  >
    <WidgetClickHoldSection :isDark="props.isDark">
      <template v-slot:mask>
        <div class="p-top-xl-1 p-top-m-1">
          <div v-html="props.overlapText"></div>
        </div>
      </template>
      <template v-slot:body>
        <div class="p-top-xl-1 p-top-m-1">
          <div v-html="props.text"></div>
        </div>
      </template>
    </WidgetClickHoldSection>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonClickHoldCursorMask',
}
</script>

<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  overlapText: {
    type: String,
    required: true,
  },
  isDark: {
    type: Boolean,
    defaul: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
