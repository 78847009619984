<template>
  <div
    v-if="props.item?.slides"
    class="common-general-carousel"
    :class="{ '--is-vertical': props.item?.verticalRatio }"
  >
    <WidgetSliderSwiper
      :length="props.item?.slides?.length"
      :centered-slides="true"
      :pagination="false"
    >
      <template
        v-for="(slide, index) in props.item?.slides"
        :key="slide.id"
        #[`slide-${index}`]
      >
        <PagesCommonGeneralCarouselSlide
          :item="slide"
          :is-vertical="props.item?.verticalRatio"
        />
      </template>
    </WidgetSliderSwiper>
  </div>
</template>

<script>
export default {
  name: 'CommonGeneralCarousel',
}
</script>

<script setup>
const props = defineProps({
  item: Object,
  alternative: Boolean,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
