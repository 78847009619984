<template>
  <div ref="container" class="zoom-masonry">
    <div class="sticky">
      <div
        class="el"
        :data-scale="zoomImage.scale"
        v-for="zoomImage in images"
        :key="zoomImage.id"
      >
        <div class="imageContainer">
          <WidgetPicture
            v-if="zoomImage.image"
            class="is-cover"
            :image="{ custom: zoomImage.image.responsiveImage }"
            :lazyLoad="false"
            :decodeMethod="'async'"
            :alt="
              zoomImage.image?.responsiveImage?.alt !== null
                ? zoomImage.image.responsiveImage.alt
                : runtimeConfig.public.siteName
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const runtimeConfig = useRuntimeConfig()

const container = ref(null)

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
  },
})

const scaleTargetOnScroll = (target, scaleMax = 4) => {
  // Animate the scale based on scroll progress
  gsap.to(target, {
    scale: scaleMax, // final scale
    scrollTrigger: {
      trigger: container.value,
      start: 'top top', // equivalent to 'start start'
      end: 'bottom bottom', // equivalent to 'end end'
      scrub: true, // smooth animation on scroll
      invalidateOnRefresh: true, // recalculate values on refresh
    },
  })
}

onMounted(() => {
  // https://chatgpt.com/g/g-2DQzU5UZl-code-copilot/c/66fe92bc-7bfc-8001-b95a-8ceb229cc6d9
  if (container.value) {
    const targets = container.value.querySelectorAll('[data-scale]')
    targets.forEach((target) => {
      const scaleValue = target.getAttribute('data-scale')
      scaleTargetOnScroll(target, scaleValue)
    })
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
