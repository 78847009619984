<template>
  <div
    class="mask-carousel-swiper-wrap"
    data-no-cursor
    :class="{ '--first-slide': isFirstSlide, '--last-slide': isLastSlide }"
  >
    <WidgetSliderSwiperParallax
      @swiper-init="handleInit"
      :length="props.data?.images?.length"
    >
      <template
        v-for="(slide, index) in props.data.images"
        :key="index"
        #[`slide-${index}`]
      >
        <div
          class="slide-inner"
          :style="{ 'background-image': `url(${slide.url})` }"
        ></div>
      </template>
    </WidgetSliderSwiperParallax>
    <div
      v-if="!touch"
      class="mask-carousel-swiper__prev-area"
      @click="handleClick('prev')"
    />
    <div
      v-if="!touch"
      class="mask-carousel-swiper__next-area"
      @click="handleClick('next')"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const touch = ref(false)
const swiperRef = ref(null)

const handleClick = (action) => {
  if (action === 'prev') {
    swiperRef.value.slidePrev()
  } else if (action === 'next') {
    swiperRef.value.slideNext()
  }
}

const handleInit = (swiperInstance) => {
  swiperRef.value = swiperInstance
}

const actualIndex = computed(() => {
  if (!swiperRef?.value) return null
  return swiperRef.value.activeIndex
})

const isFirstSlide = computed(() => {
  return actualIndex.value === 0
})

const isLastSlide = computed(() => {
  return actualIndex.value === props.data?.images?.length - 1
})

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})
</script>

<style lang="scss">
@import './style.scss';
</style>
