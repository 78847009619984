<template>
  <NuxtLink
    v-if="props.link"
    :to="props.link"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    class="hover-list-item"
  >
    <div
      v-if="props.title"
      class="hover-list-item-title title-l-1"
      v-html="props.title"
    />
    <div class="hover-list-item-img show-for-large">
      <img src="@/assets/img/ico/arrow-link-right.svg" :alt="props.title" />
    </div>
  </NuxtLink>
</template>

<script setup>
const emit = defineEmits(['set-modal'])

// Defining the props passed to the component
const props = defineProps({
  index: Number,
  title: String,
  link: String,
  setModal: Function,
})

const touch = ref(false)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})

// Functions for handling mouse enter and leave events
const handleMouseEnter = () => {
  if (touch.value) return
  emit('set-modal', { active: true, index: props.index })
}
const handleMouseLeave = () => {
  if (touch.value) return
  emit('set-modal', { active: false, index: props.index })
}
</script>

<style lang="scss">
@import './style.scss';
</style>
