<template>
  <article
    class="common-general-grid-block button-circle-1-trigger"
    ref="block"
    :class="{
      'is-hover': isHover,
      'is-alternative': alternative,
    }"
  >
    <!-- Content -->
    <div
      class="common-general-grid-block__content m-top-24px"
      :class="{
        '--is-medium': props.item?.isMedium,
        '--is-small': props.item?.isSmall,
        '--is-vertical': props.item?.isVertical,
        '--is-full-width': props.item?.fullWidth,
        '--hide-media-mobile': props.item?.hideMediaOnMobile,
      }"
    >
      <!-- If Image -->
      <div
        class="common-general-grid-block__content__preview curved-border"
        data-animate="block-image-preview"
        v-if="!hasPreview"
      >
        <WidgetPicture
          v-if="!props.item?.icon"
          class="is-cover"
          :image="{ custom: props.item?.image?.responsiveImage }"
          :lazyLoad="true"
          :retination="props.retination"
          :alt="props.item?.image?.responsiveImage?.alt"
        />
        <img
          v-else
          :src="props.item?.icon?.url"
          class="common-general-grid-block__content__icon"
          alt="Icon"
        />
      </div>

      <!-- If video -->
      <div
        v-if="hasPreview"
        class="common-general-grid-block__content__video"
        data-animate="block-video-preview"
      >
        <WidgetVideo
          class="is-cover curved-border"
          :videoUrl="props.item?.linkVideoPreview"
          :loop="true"
          :forcePlay="true"
          :autoplay="true"
          :muted="true"
          :preload="'auto'"
          :playInViewport="true"
          @play="onViewportEnter"
          @pause="onViewportLeave"
        />
      </div>

      <!-- Quick View button -->
      <div
        class="common-general-grid-block__content__quickview"
        v-if="hasQuickView"
      >
        <button
          class="button-round-1 is-transparent"
          data-animate="block-quick-view"
          @click="(event) => onQuickView(event)"
        >
          <span>{{ $t('cta.quickView') }}</span>

          <img
            src="@/assets/img/ico/ico-eye-white.svg"
            width="19"
            height="12"
            alt="White eye"
          />
        </button>
      </div>

      <!-- Content text -->
      <div
        v-if="
          props.item?.title ||
          props.item?.description ||
          (props.item?.linkLabel && props.item?.linkUrl)
        "
        class="common-general-grid-block__content__text"
      >
        <div
          v-if="props.item.title"
          class="text-corpo-xxl"
          v-html="props.item.title"
        />
        <div
          v-if="props.item.description"
          class="text-corpo-l show-for-large m-top-24px"
          v-html="props.item.description"
        />
        <div
          v-if="props.item?.linkLabel && props.item?.linkUrl"
          class="common-general-grid-block__content__link"
        >
          <WidgetArrowLink
            :label="props.item?.linkLabel"
            :link="props.item?.linkUrl"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'CommonGeneralGridBlock',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  item: Object,
  alternative: Boolean,
  retination: Boolean,
})

const block = ref(null)

const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const durationFast = 0.4
const ease = 'power2.inOut'

const hasPreview = computed(() => props.item?.linkVideoPreview)
const hasQuickView = computed(() => props.item?.linkVideo)

const quickview = useState('quickview', () => null)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
})

const onMouseEnter = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')
  const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')

  isHover.value = true

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }

  if (hasQuickView.value) {
    gsap.to(button, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
  }
}

const onMouseLeave = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')
  const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')

  isHover.value = false

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }

  if (hasQuickView.value) {
    gsap.to(button, { y: '100%', duration: durationFast, ease, stagger: 0.1 })
  }
}

const onViewportEnter = () => {
  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')
  const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
  if (hasQuickView.value) {
    gsap.to(button, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
  }
}

const onViewportLeave = () => {
  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')
  const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
  if (hasQuickView.value) {
    gsap.to(button, { y: '100%', duration: durationFast, ease })
  }
}

const onQuickView = (event) => {
  event.stopPropagation() // Previene la propagazione del click al link
  event.preventDefault() // Previene l'azione predefinita del bottone

  quickview.value = props.item?.linkVideo
}
</script>

<style lang="scss">
@import './style.scss';
</style>
