<template>
  <div ref="panelList" class="panel-list row-40-25">
    <div
      v-for="(panel, panelIndex) in props.panels"
      :key="panel.id"
      class="panel-item"
      :ref="(el) => setRefs(panelIndex, el)"
    >
      <div v-if="panel.image" class="panel-img curved-border">
        <WidgetPicture
          class="is-cover"
          :image="{ custom: panel.image?.responsiveImage }"
          :lazyLoad="true"
          :alt="panel.title || panel.description"
        />
      </div>

      <div
        v-if="
          panel.title || panel.description || (panel.linkLabel && panel.linkUrl)
        "
        class="panel-text p-left-8px p-right-8px"
      >
        <div
          v-if="panel.title"
          class="panel-title text-sans-xl is-bold"
          v-html="panel.title"
        />
        <div
          v-if="panel.description"
          class="panel-description text-sans-l show-for-large"
          v-html="panel.description"
        />
        <div v-if="panel.linkLabel && panel.linkUrl" class="panel-link">
          <WidgetArrowLink
            :label="panel.linkLabel"
            :link="panel.linkUrl"
            :is-secondary="true"
          />
        </div>
        <div
          v-if="panel.linkSecondaryLabel && panel.linkSecondaryUrl"
          class="panel-link"
        >
          <WidgetArrowLink
            :label="panel.linkSecondaryLabel"
            :link="panel.linkSecondaryUrl"
            :is-secondary="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonPanelList',
}
</script>

<script setup>
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

const props = defineProps({
  panels: {
    type: Array,
    default: () => [],
  },
})

const panelList = ref(null)
const leftBlock = ref(null)
const rightBlock = ref(null)
const middleBlock = ref(null)

const setRefs = (index, el) => {
  if (index === 0) {
    leftBlock.value = el
  } else if (index === 2) {
    rightBlock.value = el
  } else {
    middleBlock.value = el
  }
}

onMounted(() => {
  if (middleBlock.value) {
    const middleElement = middleBlock.value
    if (middleElement) {
      gsap.to(middleElement, {
        yPercent: props.reverse ? -20 : 15,
        ease: 'none',
        scrollTrigger: {
          trigger: panelList.value,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
    }
  }

  ScrollTrigger.refresh()
})
</script>

<style lang="scss">
@import './style.scss';
</style>
