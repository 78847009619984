<template>
  <div
    class="contact-block"
    :class="{
      '--hide-bar': props.data.hideBar,
      '--highlight': props.data.isHighlighted,
    }"
  >
    <div
      v-if="props.data.pretitle || props.data.title"
      class="contact-block-header is-text-center"
    >
      <div
        v-if="props.data.pretitle"
        class="text-sans-caption"
        v-html="props.data.pretitle"
      />
      <div
        v-if="props.data.title"
        class="text-corpo-xxl"
        v-html="props.data.title"
      />
    </div>

    <div
      ref="contactBlockList"
      class="contact-block-list flex is-justify-center"
    >
      <div
        v-for="block in props.data.items"
        :key="block.id"
        class="contact-block-item is-text-center"
        :style="{ '--item-width': itemMinimumSize + 'px' }"
      >
        <div
          v-if="block.title"
          class="contact-block-item-title text-corpo-xxl"
          v-html="block.title"
        />

        <div
          v-if="block.linkLabel && block.linkUrl"
          class="contact-block-item-link"
          data-no-cursor
        >
          <NuxtLink
            :to="block.linkUrl"
            :class="{
              'button-round-1': block.ctaTheme === 'brown',
              'button-round-1-blue': block.ctaTheme === 'blue',
              'button-round-1-brown-red': block.ctaTheme === 'brick',
            }"
          >
            <span>{{ block.linkLabel }}</span>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonContactBlock',
}
</script>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const contactBlockList = ref(null)

const itemMinimumSize = computed(() => {
  if (!contactBlockList?.value) return null

  const blockList = contactBlockList?.value
    ? [...contactBlockList.value.querySelectorAll('.contact-block-item')]
    : []
  if (!blockList?.length) return null

  const minimumSize = blockList.reduce((prev, curr) => {
    return (curr?.offsetWidth || 0) >= (prev?.offsetWidth || 0) ? curr : prev
  })

  return minimumSize?.offsetWidth
})
</script>

<style lang="scss">
@import './style.scss';
</style>
