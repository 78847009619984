<template>
  <div ref="videoComponent" class="video-corporate" data-pointer="play">
    <div class="video-page__video" @click="toggleVideo">
      <WidgetVideo
        :loop="true"
        :controls="false"
        :muted="true"
        :autoplay="true"
        :playsinline="true"
        :preload="'auto'"
        :video-url="videoBackgroundSrc"
      />
      <div ref="playBtn" class="video-page__play">
        <img
          src="@/assets/img/ico/video-play.svg"
          width="48"
          height="50"
          alt="Play"
        />
      </div>
      <div ref="blackOverlay" class="black-overlay"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetCursorVideo',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  video: {
    type: String,
    default: '',
  },
  videoOverlay: {
    type: Object,
    default: () => ({}),
  },
})

const runtimeConfig = useRuntimeConfig()

const matchDesktop = useHelpers().evalMatchMedia('(min-width: 1000px)')

const setVideo = useState('set-video', () => null)
const setVisibility = useState('set-visibility', () => false)

const videoComponent = ref(null)
const playBtn = ref(null)
const blackOverlay = ref(null)
const isExpanded = ref(false)
const originalSize = ref({ width: '', height: '' })
const originalPosition = ref({ top: '', left: '', x: '', y: '' })

const toggleVideo = () => {
  isExpanded.value = !isExpanded.value
  animateVideo(isExpanded.value ? 'expand' : 'shrink')
}

const animateVideo = (action) => {
  const isExpand = action === 'expand'
  isExpanded.value = isExpand

  setTimeout(() => {
    setVideo.value = props.videoOverlay
    setVisibility.value = true
  }, 650)

  if (!matchDesktop.value) {
    return
  }

  if (isExpand) saveOriginalStyles()
  animateVideoComponent(isExpand)
  animateBlackOverlay(isExpand)
}

const animateVideoComponent = (isExpand) => {
  const timeline = gsap.timeline({
    onComplete: () => {
      if (isExpand) animateVideo('shrink')
    },
  })

  /* timeline.to(videoComponent.value, {
    duration: isExpand ? 0.8 : 1,
    overflow: 'hidden',
    width: isExpand ? '100%' : originalSize.value.width,
    height: isExpand ? '100%' : originalSize.value.height,
    position: isExpand ? 'fixed' : 'absolute',
    top: isExpand ? '0' : originalPosition.value.top,
    left: isExpand ? '0' : originalPosition.value.left,
    x: isExpand ? '0' : originalPosition.value.x,
    y: isExpand ? '0' : originalPosition.value.y,
    ease: 'power1.out',
  }) */
  timeline.to(playBtn.value, {
    duration: 0.2,
    opacity: isExpand ? 0 : 1,
    ease: 'power1.out',
  })
  timeline.to(videoComponent.value, {
    duration: isExpand ? 0.8 : 1,
    overflow: 'hidden',
    scale: isExpand ? 7 : 1,
    ease: 'power1.out',
  })
}

const animateBlackOverlay = (isExpand) => {
  const timeline = gsap.timeline()

  timeline.to(blackOverlay.value, {
    duration: isExpand ? 0.8 : 1,
    opacity: isExpand ? 1 : 0,
    ease: 'power1.out',
  })
}

const saveOriginalStyles = () => {
  const videoStyle = window.getComputedStyle(videoComponent.value)
  originalSize.value.width = videoStyle.width
  originalSize.value.height = videoStyle.height
  originalPosition.value.top = videoStyle.top
  originalPosition.value.left = videoStyle.left
  if (videoStyle.transform && videoStyle.transform.includes(',')) {
    const transformValues = videoStyle.transform.split(',')
    originalPosition.value.x = transformValues[4]?.trim()
    originalPosition.value.y = transformValues[5]?.replace(')', '').trim()
  }
}

const followCursor = (event) => {
  // Se già aperto o mobile il video non fluttua
  if (isExpanded.value || !matchDesktop.value) return

  // TODO: capire come replicare la logica del parent sul sito di da guido...
  const box = videoComponent.value.closest('section').getBoundingClientRect()
  // const box = { top: 0, right: 0, bottom: 0, left: 0 }
  const videoBox = videoComponent.value.getBoundingClientRect()
  const marginLeftRight = 0
  const marginTopBottom = 0
  const left = Math.max(
    box.left + 30,
    Math.min(
      box.right - marginLeftRight - videoBox.width,
      event.clientX - videoBox.width / 2,
    ),
  )
  const top = Math.max(
    box.top + marginTopBottom,
    Math.min(
      box.bottom - marginTopBottom - 800,
      event.clientY - videoBox.height / 2,
    ),
  )

  // const left = event.clientX - videoBox.width / 2
  // const top = event.clientY - videoBox.height / 2

  // console.log(videoBox, left - box.left, top - box.top)
  const timeline = gsap.timeline()

  timeline.to(videoComponent.value, {
    duration: 1.5,
    left: left - box.left,
    top: top - box.top,
    ease: 'power1.out',
  })
}

const videoBackgroundSrc = computed(() => {
  return runtimeConfig.public.baseURL !== 'https://www.ristorantedaguido.com'
    ? '/daguido_intro.mp4'
    : props.video
})

onMounted(() => {
  // window.addEventListener('mousemove', followCursor)
})

onUnmounted(() => {
  // window.removeEventListener('mousemove', followCursor)
})
</script>

<style lang="scss">
@import './style.scss';
</style>
