<template>
  <div data-no-cursor>
    <div
      v-if="pretitle"
      class="list-mouse-hover__heading is-text-center text-sans-caption"
      v-html="pretitle"
    />

    <WidgetListMouseHoverItem
      v-for="(item, index) in list"
      :key="index"
      :index="index"
      :title="item.title"
      :link="item.link"
      :cover-image="item.coverImage"
      @set-modal="setModal"
    />
    <WidgetListMouseHoverModal :modal="modal" :list="list" />
  </div>
</template>

<script setup>
// Defining the props passed to the component
const props = defineProps({
  pretitle: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [],
  },
})

// Modal state using ref for reactivity
const modal = ref({ active: false, index: 0 })

// Method to update modal state
const setModal = (newState) => {
  modal.value = { ...modal.value, ...newState }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
