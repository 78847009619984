<template>
  <section
    data-no-cursor
    class="widget-intro-text-block"
    data-animate="preloader-opacity"
    :class="{
      'p-top-xxl-1 p-bottom-xxl-1': !isEmbed && !isInBody,
      '--is-embed': isEmbed,
      '--is-in-body': isInBody,
      'is-left-aligned': data?.isLeftAlign,
    }"
  >
    <!-- Intro Img-->
    <div v-if="data?.introImage?.url" class="widget-intro-text-block-img">
      <img :src="data?.introImage?.url" alt="Intro" />
    </div>

    <!-- Intro Video -->
    <WidgetCursorVideo
      v-if="data.videoBackgroundUrl && data.videoStreaming"
      :video="data.videoBackgroundUrl"
      :video-overlay="data.videoStreaming"
    />

    <!-- Intro Text-->
    <div
      v-if="text"
      class="widget-intro-text-block-content"
      :class="{
        'text-corpo-xl': smallFontSize,
        'text-corpo-xxl': !smallFontSize,
        'row-40-25': !isEmbed,
      }"
      v-html="text"
    />

    <!-- Intro Link-->
    <div
      v-if="data.linkLabel && data.linkUrl"
      class="widget-intro-text-block-link"
      :class="{ 'row-40-25': !isEmbed }"
    >
      <WidgetArrowLink
        :label="data.linkLabel"
        :link="data.linkUrl"
        :theme="data.ctaTheme"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'WidgetIntroTextBlock',
}
</script>

<script setup>
const props = defineProps({
  text: String,
  smallFontSize: Boolean,
  data: {
    type: Object,
    default: () => ({}),
  },
  isEmbed: {
    type: Boolean,
    default: false,
  },
  isInBody: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
