<template>
  <div class="fullscreen-image row-40-25">
    <WidgetPicture
      v-if="props.data.image"
      class="is-cover curved-border"
      :class="{ 'hide-for-small': props.data.imageMobile }"
      :image="props.data.image"
      :alt="props.data.image?.responsiveImage?.alt"
    />
    <!-- Immagine Mobile dedicata (opzionale) -->
    <WidgetPicture
      v-if="props.data.imageMobile"
      class="is-cover hide-for-medium"
      :image="props.data.imageMobile"
      :alt="props.data.imageMobile?.responsiveImage?.alt"
    />
  </div>
</template>

<script>
export default {
  name: 'PagesCommonFullscreenImage',
}
</script>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
