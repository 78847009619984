<template>
  <div
    data-no-cursor
    class="common-cursor-mask"
    :class="{
      'is-light': !props.isDark,
      'is-dark': props.isDark,
    }"
  >
    <WidgetCursorMask :text="props.text" :overlapText="props.overlapText" />
  </div>
</template>

<script>
export default {
  name: 'PagesCommonCursorMask',
}
</script>

<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  overlapText: {
    type: String,
    required: true,
  },
  isDark: {
    type: Boolean,
    defaul: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
