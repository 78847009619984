<template>
  <swiper
    class="slider-swiper-parallax"
    :class="{
      '--no-padding-bottom': props.length <= 1,
    }"
    :modules="[Pagination, A11y]"
    :pagination="{
      el: '.swiper-progress',
      type: 'progressbar',
    }"
    :speed="1000"
    :grabCursor="true"
    :watchSlidesProgress="true"
    :mousewheelControl="true"
    :keyboardControl="true"
    :lazy="true"
    @progress="onProgress"
    @touchStart="onTouchStart"
    @setTransition="onSetTransition"
    @swiper="onSwiper"
  >
    <swiper-slide v-for="index in props.length" :key="index">
      <slot :name="`slide-${index - 1}`" />
    </swiper-slide>

    <!-- If we need pagination -->
    <div class="swiper-progress" />
  </swiper>
</template>

<script>
export default {
  name: 'WidgetSliderSwiperParallax',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

import { Pagination, A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import IconArrowNext from '@/assets/img/ico/arrow-right-carousel-black.svg'
import IconArrowPrev from '@/assets/img/ico/arrow-left-carousel-black.svg'

const emit = defineEmits(['swiper-init'])

const props = defineProps({
  length: {
    type: Number,
    default: 0,
  },
  options: {
    type: Object,
  },
})

const slider = ref(null)
const nextButton = ref(null)
const prevButton = ref(null)

const touch = ref(false)

const interleaveOffset = 0.5

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})

const onSwiper = (swiper) => {
  slider.value = swiper
  emit('swiper-init', swiper)
}

const onProgress = (swiperInstance, progress) => {
  var swiper = slider.value
  if (!swiper) return

  for (let index = 0; index < swiper.slides.length; index++) {
    var slideProgress = swiper.slides[index].progress
    var innerOffset = swiper.width * interleaveOffset
    var innerTranslate = slideProgress * innerOffset
    swiper.slides[index].querySelector('.slide-inner').style.transform =
      'translate3d(' + innerTranslate + 'px, 0, 0)'
  }
}

const onTouchStart = (swiperInstance, event) => {
  var swiper = slider.value
  if (!swiper) return
  for (let i = 0; i < swiper.slides.length; i++) {
    swiper.slides[i].style.transition = ''
  }
}

const onSetTransition = (swiperInstance, speed) => {
  var swiper = slider.value
  if (!swiper) return
  for (let i = 0; i < swiper.slides.length; i++) {
    swiper.slides[i].style.transition = speed + 'ms'
    swiper.slides[i].querySelector('.slide-inner').style.transition =
      speed + 'ms'
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
