<template>
  <div class="common-body" data-animate="preloader-opacity">
    <section
      v-for="item in bodyItems.filter((bodyItem) => !bodyItem.hideSection)"
      :key="item.id"
      :class="{
        'p-top-xxl-1':
          item.__typename !== 'MaskTextRecord' &&
          !(item.__typename === 'ContactBlockRecord' && item.isHighlighted),
        'p-bottom-xxl-1': item.__typename === 'ContactsBlockSlotRecord',
        'height-90':
          item.__typename === 'ImageVideoBlockRecord' ||
          item.__typename === 'FullscreenImageRecord',
      }"
      :style="{
        '--background-color': item.backgroundColor?.hex,
        '--text-color': item.textColor?.hex,
      }"
    >
      <template v-if="item.__typename === 'IntroBlockRecord' && item.introText">
        <WidgetIntroTextBlock
          :text="item.introText"
          :data="item"
          :is-in-body="true"
        />
      </template>
      <template
        v-if="
          item.__typename === 'MaskTextRecord' && item.text && item.overlapText
        "
      >
        <!-- Mask Cursor se non è touch -->
        <PagesCommonCursorMask
          v-if="!touch"
          :text="item.text"
          :overlapText="item.overlapText"
          :isDark="item.isDark"
        />
        <!-- Hold button se è touch -->
        <PagesCommonClickHoldCursorMask
          v-if="touch"
          :text="item.text"
          :overlapText="item.overlapText"
          :isDark="item.isDark"
        />
      </template>
      <template
        v-if="item.__typename === 'ZoomMasonryRecord' && item.images?.length"
      >
        <WidgetZoomMasonry :images="item.images" />
      </template>
      <template
        v-if="item.__typename === 'ListingMenuListRecord' && item.list?.length"
      >
        <WidgetListMouseHover :pretitle="item.pretitle" :list="item.list" />
      </template>
      <template
        v-if="item.__typename === 'FullscreenImageRecord' && item.image"
      >
        <PagesCommonFullscreenImage :data="item" />
      </template>
      <template
        v-if="
          item.__typename === 'GridSectionRecord' &&
          item.gridRows?.length &&
          !item.hideSection
        "
      >
        <PagesCommonGridSection :data="item" />
      </template>
      <template
        v-if="item.__typename === 'LinkPanelListRecord' && item.panels?.length"
      >
        <PagesCommonPanelList :panels="item.panels" />
      </template>
      <template
        v-if="
          item.__typename === 'ContactsBlockSlotRecord' &&
          item.slot?.items?.length
        "
      >
        <PagesCommonContactBlock :data="item.slot" />
      </template>
      <template
        v-if="item.__typename === 'ContactBlockRecord' && item.items?.length"
      >
        <PagesCommonContactBlock :data="item" />
      </template>
      <template
        v-if="item.__typename === 'CardCarouselRecord' && item.slides?.length"
      >
        <PagesCommonGeneralCarousel :item="item" />
      </template>
      <template
        v-if="item.__typename === 'InfoBannerSlotRecord' && item.banner"
      >
        <PagesCommonInfoBanner :banner="item.banner" />
      </template>
      <template v-if="item.__typename === 'GiftADinnerSlotRecord' && item.slot">
        <PagesCommonGiftADinnerList :data="item.slot" />
      </template>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonBody',
}
</script>

<script setup>
const props = defineProps({
  bodyItems: {
    type: Array,
    default: () => [],
  },
  touch: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
