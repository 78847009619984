<template>
  <div :id="data.anchorHtml" class="grid-section p-bottom-xl-1">
    <WidgetContentStickyExsud :preTitle="data.pretitle" :title="data.title">
      <template v-slot:content>
        <PagesCommonGridParallax
          v-for="(gridRow, index) in data.gridRows"
          :key="index"
          :items="gridRow.items"
          :reverse="index % 2 !== 0"
          :class="{
            'p-top-xl-1': index > 0,
            '--is-full-width': gridRow.fullWidth,
            '--small-height': gridRow.smallHeight,
          }"
        />
      </template>
    </WidgetContentStickyExsud>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonGridSection',
}
</script>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
