<template>
  <section
    class="cursor-mask"
    ref="containerRef"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div ref="maskRef" class="cursor-mask__mask" v-show="isCursorVisible">
      <div
        class="cursor-mask__hidden-body"
        @mouseenter="toggleHover(true)"
        @mouseleave="toggleHover(false)"
        v-html="props.overlapText"
      ></div>
    </div>
    <div class="cursor-mask__body">
      <div v-html="props.text"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WidgetCursorMask',
}
</script>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { lerp } from '~/assets/js/utils.js'
import { gsap } from 'gsap'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  overlapText: {
    type: String,
    required: true,
  },
})

const useMousePosition = (containerRef) => {
  const targetX = ref(0)
  const targetY = ref(0)

  const updateMousePosition = (event) => {
    if (containerRef.value) {
      const rect = containerRef.value.getBoundingClientRect()
      targetX.value = event.clientX - rect.left
      targetY.value = event.clientY - rect.top
    }
  }

  onMounted(() => {
    if (containerRef.value) {
      containerRef.value.addEventListener('mousemove', updateMousePosition)
    }

    // Check if mouse is already inside the component on load
    document.addEventListener('mousemove', (event) => {
      if (containerRef.value) {
        const rect = containerRef.value.getBoundingClientRect()
        if (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        ) {
          isCursorVisible.value = true
          updateMousePosition(event)
        }
      }
    })
  })

  onUnmounted(() => {
    if (containerRef.value) {
      containerRef.value.removeEventListener('mousemove', updateMousePosition)
    }
  })

  return { targetX, targetY }
}

const isHovered = ref(false)
const isCursorVisible = ref(false)
const containerRef = ref(null)
const { targetX, targetY } = useMousePosition(containerRef)

const x = ref(0)
const y = ref(0)
const size = ref(40)
const maskRef = ref(null)

const toggleHover = (state) => {
  isHovered.value = state
  gsap.to(size, {
    value: state ? 550 : 40,
    duration: 0.5,
    ease: 'power2.out',
  })
}

const updateMaskPosition = () => {
  x.value = lerp(x.value, targetX.value, 0.1)
  y.value = lerp(y.value, targetY.value, 0.1)

  if (maskRef.value) {
    gsap.to(maskRef.value, {
      WebkitMaskPosition: `${x.value - size.value / 2}px ${
        y.value - size.value / 2
      }px`,
      WebkitMaskSize: `${size.value}px`,
      duration: 0.1,
      ease: 'none',
    })
  }

  requestAnimationFrame(updateMaskPosition)
}

const mouseEnter = () => {
  isCursorVisible.value = true
}

const mouseLeave = () => {
  gsap.to(maskRef.value, {
    scale: 0,
    duration: 0.5,
    ease: 'power2.out',
    onComplete: () => {
      isCursorVisible.value = false
      gsap.set(maskRef.value, { scale: 1 }) // Reset the scale for future mouse enters
    },
  })
}

onMounted(() => {
  if (maskRef.value) {
    gsap.set(maskRef.value, {
      WebkitMaskPosition: `${x.value - size.value / 2}px ${
        y.value - size.value / 2
      }px`,
      WebkitMaskSize: `${size.value}px`,
    })
  }

  updateMaskPosition()
})
</script>

<style lang="scss">
@import './style.scss';
</style>
