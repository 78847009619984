<template>
  <div
    v-if="props.items"
    ref="gridParallax"
    class="grid-parallax"
    :class="{
      'row-190-40': !isWideRow,
      'row-40-25': isWideRow,
      '--side-text-row': hasSideText,
      'still-row': isStillRow,
    }"
  >
    <div
      v-for="(item, index) in props.items"
      :key="item.id"
      :ref="(el) => setRefs(index, el)"
      class="grid-parallax__block"
      :class="{
        'is-left': isLeftBlock(index),
        'is-right': isRightBlock(index),
        'is-single': props.items?.length === 1,
        'is-center': isCenterBlock,
        'is-big': false,
        'is-small': false,
        'is-high': false,
      }"
    >
      <div class="grid-parallax__block__content">
        <template
          v-if="
            item.__typename === 'GridBlockItemRecord' ||
            item.__typename === 'ImageVideoBlockRecord'
          "
        >
          <PagesCommonGeneralGridBlock
            :item="item"
            :key="item.id"
            :retination="props.retination"
            :class="{
              '--has-text': item.sideText,
              '--has-icon': item.icon,
              '--small-height': item.smallHeight,
            }"
          />
        </template>
        <template v-if="item.__typename === 'GridBlockItemTextRecord'">
          <PagesCommonGeneralGridBlockText
            v-if="props.items.length > 1 && !item.fullWidth"
            :item="item"
            :key="item.id"
            :alternative="true"
          />
          <WidgetIntroTextBlock
            v-else
            :text="item.text"
            :small-font-size="true"
            :data="{
              linkLabel: item.linkLabel,
              linkUrl: item.linkUrl,
              ctaTheme: item.ctaTheme,
              isLeftAlign: item.isLeftAlign,
            }"
            :is-embed="true"
          />
        </template>
        <template v-if="item.__typename === 'TwoColumnsGridTextRecord'">
          <PagesCommonTwoColTextBlock
            :item="item"
            :key="item.id"
            class="m-top-l-2"
          />
        </template>
        <template
          v-if="item.__typename === 'MaskCarouselRecord' && item.images?.length"
        >
          <WidgetMaskCarouselSwiper :data="item" />
        </template>
        <template
          v-if="item.__typename === 'ContactBlockRecord' && item.items?.length"
        >
          <PagesCommonContactBlock :data="item" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonGridParallax',
}
</script>

<script setup>
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  reverse: Boolean,
  retination: Boolean,
})

const gridParallax = ref(null)
const leftBlock = ref(null)
const rightBlock = ref(null)

const setRefs = (index, el) => {
  if (index % 2 == 0) {
    leftBlock.value = el
  } else {
    rightBlock.value = el
  }
}

const isWideRow = computed(() =>
  props.items?.find(
    (item) =>
      item.__typename === 'MaskCarouselRecord' ||
      (item.__typename === 'GridBlockItemRecord' && item.fullWidth),
  ),
)

const hasSideText = computed(() =>
  props.items?.find(
    (item) => item.__typename === 'GridBlockItemRecord' && item.sideText,
  ),
)

const isCenterBlock = computed(
  () =>
    props.items?.length === 1 &&
    props.items?.find(
      (item) => item.__typename === 'GridBlockItemRecord' && item.isCenter,
    ),
)

const isStillRow = computed(
  () =>
    props.items?.length &&
    props.items?.every((item) => item.__typename === 'GridBlockItemTextRecord'),
)

const isRightBlock = (itemIndex) => {
  return (
    itemIndex % 2 !== 0 ||
    (props.items.length === 1 &&
      props.items?.find(
        (item) => item.__typename === 'GridBlockItemRecord' && item.isRight,
      ))
  )
}

const isLeftBlock = (itemIndex) => {
  return (
    itemIndex % 2 == 0 &&
    props.items.length !== 1 &&
    !props.items?.find(
      (item) => item.__typename === 'GridBlockItemRecord' && item.isRight,
    )
  )
}

onMounted(() => {
  if (rightBlock?.value) {
    const rightElement = rightBlock.value
    if (rightElement) {
      gsap.to(rightElement, {
        yPercent: !props.reverse ? -25 : 15,
        ease: 'none',
        scrollTrigger: {
          trigger: gridParallax.value,
          start: 'bottom bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
    }
  }

  if (leftBlock.value) {
    const leftElement = leftBlock.value
    if (leftElement) {
      gsap.to(leftElement, {
        yPercent: props.reverse ? -25 : 15,
        ease: 'none',
        scrollTrigger: {
          trigger: gridParallax.value,
          start: 'bottom bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
    }
  }
  ScrollTrigger.refresh()
})
</script>

<style lang="scss">
@import './style.scss';
</style>
