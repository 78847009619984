<template>
  <div class="gift-a-dinner row-40-25">
    <div class="gift-a-dinner-list">
      <div
        v-for="block in props.data.items"
        :key="block.id"
        class="gift-a-dinner-item"
      >
        <div
          v-if="block.body"
          class="gift-a-dinner-item-body"
          v-html="block.body"
        />

        <div
          v-if="block.linkLabel && block.linkUrl"
          class="gift-a-dinner-item-link"
          data-no-cursor
        >
          <a
            :href="block.linkUrl"
            rel="noopener"
            class="button-round-1-brown-red"
          >
            <span>{{ block.linkLabel }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonGiftADinnerList',
}
</script>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
