<template>
  <div class="click-hold-section">
    <div class="click-hold-section__mask">
      <div ref="maskRef" class="click-hold-section__mask__item"></div>
    </div>
    <div ref="maskContentRef" class="click-hold-section__mask__content">
      <slot name="mask" />
    </div>
    <div class="click-hold-section__body">
      <slot name="body" />
    </div>

    <button
      ref="buttonRef"
      class="click-hold-section__button"
      @mousedown="startHold"
      @mouseup="endHold"
      @mouseleave="endHold"
      @touchstart="startHold"
      @touchend="endHold"
      @touchcancel="endHold"
    >
      <img
        v-if="props.isDark"
        src="@/assets/img/ico/ico-press-white.svg"
        class="click-hold-section__button__image"
      />
      <img
        v-else
        src="@/assets/img/ico/ico-press-brown.svg"
        class="click-hold-section__button__image"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'WidgetClickHoldSection',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  isDark: {
    type: Boolean,
    defaul: false,
  },
})

const holdDuration = 500
const timer = ref(null)
const isHolding = ref(false)
const buttonRef = ref(null)
const maskRef = ref(null)
const maskContentRef = ref(null)

const scaleDuration = 0.6 // Duration for the scaling animation

const startHold = (event) => {
  event.preventDefault() // Prevent default action to avoid unwanted behavior on touch devices
  if (isHolding.value) return
  isHolding.value = true
  gsap.to(buttonRef.value, { scale: 0.9, duration: 0.4 })
  timer.value = setTimeout(() => {
    isHolding.value = false
    document.body.style.overflow = 'hidden'
    gsap.to(buttonRef.value, { scale: 1, duration: 0.4 })
    gsap.to(maskRef.value, {
      scale: 2,
      duration: scaleDuration,
      ease: 'power2.out',
    })
    gsap.to(maskContentRef.value, { opacity: 1, delay: -0.1, duration: 0.2 }) // Show content with fade
  }, holdDuration)
}

const endHold = () => {
  if (timer.value) {
    clearTimeout(timer.value)
    timer.value = null
    isHolding.value = false
    gsap.to(maskContentRef.value, {
      opacity: 0,
      duration: 0,
      onComplete: () => {
        gsap.to(maskRef.value, {
          scale: 0,
          duration: scaleDuration,
          ease: 'power2.out',
          onComplete: () => (document.body.style.overflow = 'auto'),
        })
      },
    }) // Hide content with fade before scaling down the mask
    gsap.to(buttonRef.value, { scale: 1, duration: 0.4 })
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
