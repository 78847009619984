<template>
  <article
    class="common-general-carousel__slide button-circle-1-trigger"
    ref="block"
    :class="{
      'is-hover': isHover,
    }"
  >
    <!-- Content -->
    <div class="common-general-carousel__content curved-border m-top-24px">
      <div
        class="common-general-carousel__content__preview"
        data-animate="block-image-preview"
      >
        <WidgetPicture
          class="is-cover"
          :image="{
            custom: props.isVertical
              ? props.item.portrait && props.item.portrait.responsiveImage
                ? props.item.portrait.responsiveImage
                : props.item?.image?.responsiveImage
              : props.item?.image?.responsiveImage,
          }"
          :lazyLoad="true"
          :alt="props.item?.image?.responsiveImage?.alt"
        />
      </div>
      <div
        v-if="hasPreview"
        class="common-general-carousel__content__video"
        data-animate="block-video-preview"
      >
        <WidgetVideo
          class="is-cover"
          :videoUrl="props.item?.linkVideoPreview"
          :loop="true"
          :forcePlay="true"
          :autoplay="true"
          :muted="true"
          :preload="'auto'"
          :playInViewport="true"
        />
      </div>

      <!-- Quick View button -->
      <div
        class="common-general-carousel__content__quickview hide-for-touch"
        v-if="hasQuickView"
      >
        <button
          class="button-round-1 is-transparent"
          data-animate="block-quick-view"
          @click="(event) => onQuickView(event)"
        >
          <span>{{ $t('cta.quickView') }}</span>

          <img
            src="@/assets/img/ico/ico-eye-white.svg"
            width="19"
            height="12"
            alt="White eye"
          />
        </button>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'CommonGeneralCarouselSlide',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { useSwiperSlide } from 'swiper/vue'

const props = defineProps({
  item: Object,
  isVertical: {
    type: Boolean,
    default: false,
  },
})

const swiperSlide = useSwiperSlide()

const block = ref(null)

const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const durationFast = 0.4
const ease = 'power2.inOut'

const hasPreview = computed(() => props.item?.linkVideoPreview)
const hasQuickView = computed(() => props.item?.linkVideo)

const quickview = useState('quickview', () => null)

watch(
  () => swiperSlide.value,
  (swiperSlide) => {
    const isActive = swiperSlide.isActive
    const parent = block.value
    const video = parent?.querySelector('[data-animate="block-video-preview"]')
    const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')

    if (isActive) {
      if (hasPreview.value) {
        gsap.to(video, { autoAlpha: 1, duration, ease })
      }

      if (hasQuickView.value) {
        gsap.to(button, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
      }
    } else {
      if (hasPreview.value) {
        gsap.to(video, { autoAlpha: 0, duration, ease })
      }

      if (hasQuickView.value) {
        gsap.to(button, {
          y: '101%',
          duration: durationFast,
          ease,
          stagger: 0.1,
        })
      }
    }
  },
)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
})

/*const onMouseEnter = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent.querySelector('[data-animate="block-video-preview"]')
  const button = parent.querySelectorAll('[data-animate="block-quick-view"]')
  const tags = parent.querySelectorAll('[data-animate="block-tag"]')

  isHover.value = true

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }

  if (hasQuickView.value) {
    gsap.to(button, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
  }
  if (tags.length) {
    gsap.to(tags, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
  }
}*/

/*const onMouseLeave = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent.querySelector('[data-animate="block-video-preview"]')
  const button = parent.querySelectorAll('[data-animate="block-quick-view"]')
  const tags = parent.querySelectorAll('[data-animate="block-tag"]')

  isHover.value = false

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }

  if (hasQuickView.value) {
    gsap.to(button, { y: '100%', duration: durationFast, ease, stagger: 0.1 })
  }
  if (tags.length) {
    gsap.to(tags, { y: '100%', duration: durationFast, ease, stagger: 0.1 })
  }
}*/

/*const onViewportEnter = () => {
  const parent = block.value
  const video = parent.querySelector('[data-animate="block-video-preview"]')

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onViewportLeave = () => {
  const parent = block.value
  const video = parent.querySelector('[data-animate="block-video-preview"]')

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}*/

const onQuickView = (event) => {
  event.stopPropagation() // Previene la propagazione del click al link
  event.preventDefault() // Previene l'azione predefinita del bottone

  quickview.value = props.item?.linkVideo
}
</script>

<style lang="scss">
@import './style.scss';
</style>
